define('gusto/js/app/gusto.charts',['window', 'jquery', 'domready!'], function(window, $){
    "use strict";

    var charts = {
        init: function() {
            var that = this;
            $('.bt40__tabs__tab').bind('click', function(){
                that.switch_tab($(this).index());
            });
            return this;
        },
        switch_tab: function(tab_index) {
            $('.bt40__tabs__tab').removeClass('bt40__tabs__tab--active');
            $('.bt40__tabs__tab:nth-child('+(tab_index+1)+')').addClass('bt40__tabs__tab--active');

            var entry_start = (tab_index * 10);
            var entry_end = entry_start + 10;

            var $entries = $('.bt40__entry');
            $entries.addClass('bt40__entry--hidden');
            $entries.slice(entry_start, entry_end).removeClass('bt40__entry--hidden');
        }
    };
    return charts.init();
});

